@font-face {
  font-family: "Poppins";
  src: local("Poppins-Regular"),
    url(./fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-Bold"), url(./fonts/Poppins-Bold.ttf) format("truetype");
  font-weight: 700 900;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins-SemiBold"),
    url(./fonts/Poppins-SemiBold.ttf) format("truetype");
  font-weight: 500 600;
}

body {
  margin: 0;
  font-family: "Poppins", "Source Sans Pro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
main {
  min-height: 100%;
  height: 100%;
  width: 100%;
}

@media print {
  html,
  body {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}

.react-datepicker {
  padding: 2px;
}

li.react-datepicker__time-list-item {
  color: black;
}

ul {
  margin-block-start: 0;
}

pre {
  overflow: auto;
}

/* code {
  color: #e0e0e0;
  font-size: 1.05rem;
  overflow: scroll;
} */

#root {
  height: 100%;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  background-color: #ffffff;
}
/* .react-datepicker__input-container input {
  font-size: 22px;
  font-family: "Poppins";
  padding: 10px;
  width: 100%;
  background-color: #1c1c1c;
  color: #d6d6d6;
  border-width: 1px;
  border-style: solid;
  border-color: gray;
  border-radius: 12px;
} */

/* .react-datepicker__input-container input:focus {
  outline: solid;
  outline-color: black;
  outline-width: 1px;
} */

/* .react-datepicker-time__input input {
  font-family: "Poppins";
  font-size: 16px;
} */

.Checkout.is-desktop .Button {
  background-color: #ffbb33;
}

.ChartContainer svg {
  overflow: unset !important;
}
